<template>
  <div class="gallery">
    <el-dialog
      title="我的图库"
      :visible.sync="isShowDialog"
      :before-close="hide"
      :modal-append-to-body="false"
    >
      <div class="hd">
        <a :class="menuClass('src')" href="javascript:;" @click="menuClick('src')">原图</a>
        <a :class="menuClass('psd')" href="javascript:;" @click="menuClick('psd')">已修好</a>
        <a :class="menuClass('match')" href="javascript:;" @click="menuClick('match')">赛事</a>
        <a :class="menuClass('issue')" href="javascript:;" @click="menuClick('issue')">发布</a>
      </div>
      <div class="hd1 clearfix">
        <a href="javascript:;" @click="allCheck()" v-show="checkeds"
          ><i class="ico ico-check"></i><span>全选</span></a
        >
        <a class="checked" href="javascript:;" @click="allCheck()" v-show="!checkeds"
          ><i class="ico ico-check"></i><span>全选</span></a
        >
        <a class="back" href="javascript:;" @click="go_back()"
          ><i class="ico ico-back"></i><span>返回上一级</span></a
        >
      </div>
      <div class="bd">
        <ul class="list clearfix">
          <li v-for="(i,index) in domList" :key="index" :class="changeClass(index)">
            <div class="img" v-if="i.children.length>0" @click="fileClick(index)">
              <img :src="folder" alt="" />
            </div>
            <div class="img" v-else>
              <img :src="i.thumb" alt="" />
            </div>
            <p class="text">{{i.name}}</p>
            <i class="ico ico-check1" v-if="i.children.length==0" @click="checkClick(index)"></i>
          </li>
          <!--<li class="checked">-->
            <!--<div class="img"><img :src="folder" alt="" /></div>-->
            <!--<p class="text">单图投稿投稿投稿投稿</p>-->
            <!--<i class="ico ico-check1"></i>-->
          <!--</li>-->
          <!--<li>-->
            <!--<div class="img"><img :src="folder" alt="" /></div>-->
            <!--<p class="text">单图投稿投稿投稿投稿</p>-->
          <!--</li>-->
          <!--<li>-->
            <!--<div class="img"><img :src="demo" alt="" /></div>-->
            <!--<p class="text">单图投稿投稿投稿投稿</p>-->
            <!--<i class="ico ico-check1"></i>-->
          <!--</li>-->
          <!--<li class="checked">-->
            <!--<div class="img"><img :src="demo" alt="" /></div>-->
            <!--<p class="text">单图投稿投稿投稿投稿</p>-->
            <!--<i class="ico ico-check1"></i>-->
          <!--</li>-->
        </ul>
        <!-- <div class="floder">文件夹</div>
        <div class="images">图片</div> -->
      </div>
      <span slot="footer" class="dialog-footer" @click="submitClick">
        <!-- <el-button @click="hide">取 消</el-button> -->
        <el-button type="warning">上 传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    transShowGallery: {
      type: Boolean,
    },
      end: Number,
  },
  // inject: ["reload"],
  data() {
    return {
      cur: -1,
      isShowDialog: false,
      folder: require("@/static/images/folder.png"),
      demo: require("@/static/images/pic-6.png"),
        allOldImg:[],
        domList:[],
        type:'src',
        checkIdx:[],
        path:'src',
        checkeds:true,
      // dialogVisible: true,
    };
  },
  watch: {
    transShowGallery: {
      immediate: true,
      handler(val) {
        this.isShowDialog = val;
      },
    },
  },
  methods: {
    menuClass(str){
        if(str == this.type){
            return 'cur';
        }else {
            return '';
        }
    },
    allCheck(){
        if(this.checkeds){
            for (var i=0;i<this.domList.length;i++){
                this.checkIdx.push(i);
            }
        }else {
            this.checkIdx = [];
        }
        this.checkeds = !this.checkeds;
        this.$forceUpdate();
    },
    menuClick(str){
        this.type = str;
        this.path = str;
        this.checkIdx = [];
        this.domList = this.tools.files2path(this.allOldImg,this.type);
    },
    changeClass(idx){
        if(this.checkIdx.indexOf(idx) !== -1){
            return 'checked';
        }else {
            return '';
        }
    },
    checkClick(idx){
        var i = this.checkIdx.indexOf(idx);
        if(i !== -1){
            this.checkIdx.splice(i,1);
        }else {
            this.checkIdx.push(idx);
        }
        this.$forceUpdate();
    },
    handleClose() {
      // this.$confirm("确认关闭？")
      //   .then((res) => {
      //     console.log(res);
      //     // done();
      //     this.hide();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    submitClick(){
        var len = this.checkIdx.length;
        if(len >0){
            if(this.end < len){
              this.$message.error("你已经超过选中数量");
            }else {
              var arr = [];
              for (var i=0;i<len;i++){
                  arr.push(this.domList[this.checkIdx[i]].url)
              }
              this.$emit("changeGallery",arr);
              this.checkIdx = [];
              this.isShowDialog = false;
            }
        }else {
            this.$message.error("您还没有选中作品无法上传");
        }
    },
    hide() {
      this.$emit("changeGallery",[]);
      this.checkIdx = [];
      this.isShowDialog = false;
    },
    fileClick(idx){
        this.domList = this.domList[idx].children;
        this.checkIdx = [];
        if(this.domList.length>0){
            var arr = this.domList[0].key.split('/');
            arr.pop();arr.pop();
            this.path = arr.join('/');
        }
    },
    go_back() {
        this.domList = this.tools.files2path(this.allOldImg, this.path);
        this.checkIdx = [];
        if(this.domList.length>0 && this.path.indexOf('/') !== -1){
            var arr = this.domList[0].key.split('/');
            arr.pop();arr.pop();
            this.path = arr.join('/');
        }
    },
  },
  created() {
    this.isShowDialog = this.transShowGallery;
    this.tools
        .getOldImg()
        .then((res) => {
            this.allOldImg = res;
            this.domList = this.tools.files2path(this.allOldImg,this.type);
        });
  },
};
</script>

<style lang="scss">
  html{
    height: auto !important;
  }
.gallery {
  .el-dialog {
    width: 1060px;
    height: 666px;
    border-radius: 5px;
    overflow: hidden;
  }
  .el-dialog__header {
    padding: 10px 20px;
    background: #ef7f48;
    color: #fff;
    .el-dialog__title {
      color: #fff;
    }
  }
  .el-dialog__headerbtn {
    font-size: 32px;
    top: 5px;
    right: 10px;
    .el-dialog__close {
      color: #fff;
    }
  }
  .el-dialog__body {
    padding: 0 20px;
  }
  .el-dialog__footer {
    text-align: center;
  }
  .el-button--warning {
    background: #ef7f48;
  }
  .hd {
    color: #ebebeb;
    line-height: 60px;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
    .cur,
    a:hover {
      color: #ef7f48;
    }
    a {
      font-size: 16px;
      padding: 0 30px;
      border-right: 1px solid #ebebeb;
      &.last,
      &:last-child {
        border: 0;
      }
    }
  }
  .hd1 {
    border-bottom: 1px solid #ebebeb;
    line-height: 46px;
    a,
    span,
    i {
      vertical-align: middle;
    }
    .ico {
      margin: 0 5px 0 0;
    }
    a {
      color: #333;
    }
    .back {
      float: right;
      color: #2f8cff;
    }
  }
  .bd {
    .list {
      height: 420px;
      overflow: auto;
      li {
        width: 130px;
        height: 130px;
        float: left;
        text-align: center;
        border-radius: 5px;
        margin: 10px 5px 0;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &:hover,
        &.checked {
          background: #fff5f0;
        }
        .img {
          position: relative;
          width: 84px;
          height: 84px;
          overflow: hidden;
          margin: 10px auto 0;
          img {
            position: relative;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            max-width: 100%;
            max-height: 100%;
          }
        }
        .text {
          padding: 0 5px;
          height: 34px;
          line-height: 34px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .ico {
          position: absolute;
          top: 0;
          right: 0;
          // display: none;
        }
        &:hover {
          .ico {
            display: inline-block;
          }
        }
      }
    }
  }
  .el-button {
    width: 100px;
    margin-top: 10px;
  }
}
</style>
